body {
  font-family: "DM Sans", sans-serif;
}
.sticky-top {
  border-bottom: 1px solid #DCD6D6;
  justify-content: center;
  align-items: center;
  .login-button {
    color: #106BEF;
    border: 1px solid #106BEF;
    background-color: white;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    &:hover {
      color: #fff;
      background-color: #106BEF;
      border-color: #106BEF;
    }
  }
}
.helpdesk-welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .main {
    background-image: url("../../assets/img/start/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .main-cover {
      background-color: rgba(255, 255, 255, 0.45);
      padding: 110px clamp(0px, calc((100vw - 780px) / 2), 340px) 106px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 1440px;
      flex-grow: 1;
      .center {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        font-size: 18px;
        gap: 64px;
        .welcome-header-helpdesk {
          color: $secondary;
        }
        .txt {
          display: flex;
          justify-content: center;
          margin: 20px 27px 0 27px;
          * {
            max-width: 760px;
          }
        }
      }
    }
  }
  &.loggedin {
    display: flex;
    flex-direction: row;
    max-width: 1440px;
    margin: auto;
    .main {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      .main-cover {
        padding: 110px clamp(0px, calc((100vw - 780px) / 2), 340px) 108px;
        .center {
          flex-direction: row;
          justify-content: center;
          gap: 1rem;
          flex-wrap: wrap;
          flex-grow: 1;
        }
      }
    }
  }
}
.helpdesk-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  .main {
    padding: 80px clamp(0px, calc((100vw - 780px) / 2), 250px) 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
    flex-grow: 1;
    align-items: center;
    background: #106BEF;
    max-width: 1440px;
    .center {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      font-size: 18px;
      gap: 64px;
      .welcome-footer-title {
        max-width: 588px;
        margin: auto;
      }
      .txt {
        display: flex;
        justify-content: center;
        margin: 20px 27px 0 27px;
        * {
          max-width: 760px;
        }
      }
    }
    .block {
      display: grid;
      max-width: 942px;
      gap: 86px;
      grid-template-columns: 1fr 1fr;
      .column {
        gap: 27px;
        .right-txt {
          margin-top: 8px;
        }
      }
    }
  }
}
.report-button {
  color: #fff;
  border: 1px solid #106BEF;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  background-color: #106BEF;
  &:hover {
    color: #106BEF;
    background-color: white;
    border-color: #106BEF;
  }
}
.grid-row {
  display: grid;
  gap: 40px;
  row-gap: 40px;
  margin-top: 64px;
  margin-bottom: 120px;
}
@media (min-width: 768px) {
  .grid-row {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  .grid-row {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.grid-col {
  margin: 0;
  padding: 0;
  .card {
    padding: 41px 30px;
    .main-image {
      width: 290px;
      height: 290px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .bottom-content {
      display: flex;
      flex-direction: column;
      gap: 26px;
    }
  }
}
