// Variable overrides
$body-bg: #F8F8F8;

$secondary: #106BEF;
$primary: #0fa1ff;
$dark: #979797;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 1.4;
$h2-font-size: $font-size-base * 1.2;

//$card-border-radius: 20px;
//$card-cap-bg: none;

$dropdown-color: #0fa1ff;
$dropdown-bg: #ffffff;
$dropdown-border-color: #ffffff;
//$caret-vertical-align: .3em * .50;
//$caret-spacing: .5em;

$nav-link-color: #6c757d;
$nav-link-hover-color: #0fa1ff;

$progress-border-radius: 20px;

$nav-tabs-border-width: 0px;
$nav-tabs-link-active-color: #0fa1ff;
$nav-tabs-link-active-bg: none;

$input-border-radius: 6px;
$input-font-size: 14px;
$input-border: 1px solid #777676;

$btn-font-size: 14px;
$btn-border-radius: 24px;
$btn-font-weight: 500;
$btn-padding-x: 0.9rem;
$btn-padding-y: 0.5rem;

.btn-primary, .btn-primary:active {
  background-color: #106BEF !important;
}

.btn-primary:hover {
  background-color: #0049B1 !important;
}

$modal-content-border-radius: 12px;

$box-shadow: 0 .3rem 1rem rgba(#000, .10);

@import "~bootstrap/scss/bootstrap";
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';

.btn:disabled {
  background-color: #777676;
  border: 1px solid #777676;
}

h2 {
  color: blue;
}

.title {
  margin-right: 1em;
}

.backgroundPic {
  background: url('./../../assets/img/icons/a.png') no-repeat center;
  background-size: contain;
  &:hover {
    background-image: url('./../../assets/img/icons/b.png');
  }
}
.right {
  float: right;
  width: 32px;
  height: 32px;
}

/*** Notifications ***/
/*** Example rules ***/
.redux-toastr .toastr {
  border-radius: 0px;
  .rrt-title {
  }
  .rrt-text {
  }
  &.rrt-info, &.rrt-success, &.rrt-warning, &.rrt-error, & button {
  }
  &.rrt-error {
  }
  &.rrt-success {
  }
  &.rrt-warning {
  }
  &.rrt-info {
  }
  .close-toastr {
    opacity: 0.6;
  }
}

.navbar {
  height: 3.5rem;

  img.logo {
    margin-right: 0.7em;
    height: 2rem;
    margin-top: -3px;
  }
  #main-title {
    font-size: 1rem;
  }
}

.card {
  background-color: #ffffff;
  border: 1px solid #DCD6D6;
  box-sizing: border-box;
  border-radius: 20px;

  margin-right: 2em;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: 2em;

  padding-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 1em;

}

.helpdesk {
  position: relative;

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
  }

  .img {
    width: 100%;
    height: auto;
  }

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 68px;
    text-align: center;
    color: #484848;
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    /* or 135% */

    text-align: center;

    color: #6A6464;
  }
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}

.details {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  color: #006FC4;
}

.asText {
  background:none;
  border:none;
  margin:0;
  padding:0;
}

// TinyMCE editor/viewer
.tox-tinymce {
  border: 1px solid #DCD6D6;
  .tox-editor-header {
    box-shadow: none;
    border-bottom: 1px solid #DCD6D6;
  }
}

.common-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background-color: white;
  opacity: 0.6;
  padding-top: 20px;
  border-radius: inherit;
}

@import "pracelab.scss";
